<template>
  <modal :show="show" :title="$t('photo_upload')">
    <template v-slot:header>
      <a
        class="close-button"
        @click="$emit( 'close-modal', $event )"> X </a>
      <div class="heading">
        <h3>{{$t('photo_upload')}}</h3>
      </div>
    </template>
    <template v-slot:content>
      <div class="content">
        <Dropzone
          class="FileUploader"
          :resizeWidth="resizeWidth"
          :uploadUrl="photoUploadPath"
          acceptedFiles="image/*"
          :maxFiles="1"
          :uploadText="$t('comment_upload_image')"
          :uploadHeaders="photoUploadHeaders"
          @file-uploaded="handleFileAdded"
          @file-removed="handleFileRemoved" />
        <Button
          color="accent"
          style="width: 100%"
          :disabled="buttonDisabled"
          @click="handleClick">
          {{$t('save')}} <Spinner v-show="loading" />
        </Button>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal                from '~/components/Modal';
  import Dropzone             from '~/components/Dropzone';
  import Button               from '~/components/Button';
  import Spinner              from '~/components/Spinner';
  import ApiService           from '@/services/api.service';

  export default {
    name: 'PhotoUploader',

    components: {
      Modal,
      Dropzone,
      Button,
      Spinner
    },

    props: {
      uploadUrl: {
        type: String,
        default: null,
      },
      show: {
        type: Boolean,
        default: false,
      },
      resizeWidth: {
        type: Number,
        default: null,
      }
    },

    data ( ) {
      return {
        loading             : false,
        photoUploadPath     : process.env.VUE_APP_API_URL + '/experienceLabs/file',
        photoUploadHeaders  : ApiService.getConfig().headers,
        photoUrl            : '',
        buttonDisabled      : true
      }
    },

    methods: {

      handleClick ( ) {
        this.loading = true;
        this.$emit( 'photo-saved', this.photoUrl )
      },

      handleFileAdded ( file, response ) {
        this.photoUrl         = response.path;
        this.loading          = false;
        this.buttonDisabled   = false;
      },

      handleFileRemoved ( file, response ) {
        this.$emit( 'photo-removed' );
        this.loading          = false;
        this.buttonDisabled   = true;
      }

    }
  }
</script>

<style scoped lang="scss">
  .FileUploader {
    margin: 1rem 0;
    text-align: center;
  }

  .heading {
    display: flex;
    justify-content: space-between;
  }
  .close-button {
    font-weight: bold;
    color: #db0000;
    cursor: pointer;
  }
  .content{
    display: flex;
    flex-direction: column;
  }
</style>
